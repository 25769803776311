<template>
  <v-progress-circular
      v-if="pageLoading"
      :size="50"
      :width="10"
      color="#ffbe66"
      indeterminate
  ></v-progress-circular>
  <div v-else>
    <v-alert
        v-model="NOTIFICATION_STATUS"
        :color="NOTIFICATION_COLOR"
        elevation="24"
        type="success"
        dismissible
        width="100%"
        style="position: fixed; z-index: 99999"
    >{{ NOTIFICATION }}
    </v-alert>
    <v-row>
      <v-col sm="1" md="3"></v-col>
      <v-col sm="10" md="6">
        <v-card color="#ffbe66" elevation="0" class="single-plan" style="border-radius: 25px">
          <v-card-title>
            <v-row>
              <v-col>
                <span style="font-size:30px">{{ title }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-row>
            <v-col>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-card-text>
            <v-row>
              <v-col>
                <span style="font-size:23px">{{ type }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size:20px" v-html="description"></span>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="1" md="2"></v-col>
              <v-col sm="5" md="4"><span style="font-size:30px">{{ price }} $</span></v-col>
              <v-col sm="5" md="4">
                <span v-if="expire" style="font-size:24px">{{ expire }} / days</span>
                <span v-else style="font-size:24px">∞</span>
              </v-col>
              <v-col sm="1" md="2"></v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col cols="6" class="text-center custom-y-margin">
            <v-text-field
                label="Book Code (Voucher)"
                outlined
                rounded
                color="#39bf88"
                id="voucher_code"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-btn class="mt-4 text-capitalize plan-button" elevation="0" x-large @click="buy">Buy Now  <v-icon>mdi-basket</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="1" md="3"></v-col>
    </v-row>
    <v-row class="ma-6 d-flex justify-center">
      <v-progress-circular
          v-if="isLoading"
          :size="150"
          :width="10"
          color="#ffbe66"
          indeterminate
      >
        Redirecting...
      </v-progress-circular>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Plan",
  data() {
    return {
      title: null,
      description: null,
      expire: null,
      price: null,
      type: null,
      isLoading: false,
      pageLoading: true,
    }
  },
  created() {
    window.axios.get(`api/plans/${this.$route.params.id}`)
        .then((data) => {
          this.title = data.data.data.title
          this.description = data.data.data.description
          this.expire = data.data.data.expire
          this.price = data.data.data.price
          this.type = data.data.data.type
          this.pageLoading = false
        })
  },
  methods: {
    buy() {
      this.isLoading = true
      window.axios.post('api/plans/buy/' + this.$route.params.id,{voucher:document.querySelector('#voucher_code').value}).then((response) => {
        window.location.href = response.data
        // this.NOTIFICATION = 'Your Plan is valid until: ' + response.data.data.plan_expire_time;
        // this.NOTIFICATION_STATUS = true;
        // this.NOTIFICATION_COLOR = 'green';
      }).catch((error) => {
        this.NOTIFICATION = error.response.data.errors.error ?? 'Something went wrong!';
        this.NOTIFICATION_STATUS = true;
        this.NOTIFICATION_COLOR = 'red';
      })
    }
  }
}
</script>

<style scoped>
.plan-button {
  color:white;
  width: 50%;
  border-radius: 40px;
  /*box-shadow: 1px 15px 25px 5px rgba(94, 200, 52, 0.4);*/
  background-image: linear-gradient(to left, #26bf81, #83bea5);
}
.single-plan{
  background-image: linear-gradient(to left, #1aa46c, #c7dfd4);
  /*box-shadow: 1px 20px 25px 10px rgba(94, 200, 52, 0.4) !important;*/
}
</style>